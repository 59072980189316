<template>
	<div data-component="checkbox-field">
		<validation-provider
			v-slot="{ errors, required }"
			:rules="rules"
			:vid="getVid"
			:name="getName"
		>
			<input
				type="checkbox"
				:id="getId"
				:checked="checked"
				:disabled="disabled"
				@change="onChange"
			/>
			<label
				v-if="showLabel"
				data-element="label"
				:for="getId"
				:data-required="required"
				v-html="getLabel"
			/>
			<p
				v-if="errors[0]"
				data-element="error"
			>
				{{ errors[0] }}
			</p>
		</validation-provider>
	</div>

</template>

<script>

	import { paramCase }  from 'change-case';
	import { camelCase }  from 'change-case';

	export default {
		model: {
			prop: 'checked',
			event: 'change'
		},
		props: {
			name: {
				type: String,
				required: true
			},
			id: {
				type: String,
				default: undefined
			},
			rules: {
				type: String,
				default: ''
			},
			checked: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			},
			showLabel: {
				type: Boolean,
				default: true
			}
		},
		data: () => ({}),
		computed: {
			getName () {
				return this.name;
			},
			getLabel () {
				return this.name;
			},
			getId () {
				if (!this.id) {
					return paramCase(this.getName);
				}
				return this.id;
			},
			getVid () {
				if (!this.id) {
					return camelCase(this.getName);
				}
				return this.id;
			}
		},
		methods: {
			onChange (event) {
				this.$emit('change', event.target.checked);
			}
		}
	};

</script>

<style lang="scss" scoped>

  [data-component='checkbox-field'] {
		span {
			display:flex;

			input {
				margin:auto rem(16) auto 0;
				outline:none;
				border:1px solid $c-brand-grey-lighter-2;
				border-radius:0;
				font-size:rem(12);
				line-height:32px;

				&[data-error] {
					border:1px solid $c-red;
				}

				&:hover,
				&:focus {
					border:1px solid $c-brand-grey-lighter-2;
				}
			}

			label {
				margin:0 !important;
        @include font(16px, $c-dark-navy, 700, 20px)
			}
		}
	}

</style>
