<template>
	<div
		data-component="active-filters"
	>
		<V2Button
			@click="changeFilters"
			fit
			size="sm"
		>
			Change filters
		</V2Button>
		<div data-element="filter-items">
			<badge
				v-for="(filterItemValue, filterItemKey) in activeFilters"
				:key="filterItemKey"
				@click="changeFilters"
				@clickIcon="removeFilter(filterItemKey)"
				:text="getFilterItemString(filterItemKey, filterItemValue)"
				icon="cross"
			/>
		</div>
	</div>
</template>

<script>

	import Badge from '@/components/ui/Badge';
	import { sentenceCase }  from 'change-case';
	import V2Button           from '@/components/v2/ui/V2Button';

	export default {
		components: {
			Badge,
			V2Button
		},
		props: {
			activeFilters: {
				type: Object,
				default: undefined
			},
			filters: {
				type: Array,
				default: undefined
			}
		},
		computed: {
			getSomeFiltersAreActive () {
				return Object.keys(this.activeFilters).length;
			},
			getActiveFilters () {
				// return this.activeFilters;
				return Object.keys(this.activeFilters).map((filterKey, filterIndex) => {
					return this.activeFilters[filterKey];
				});
			}
		},
		methods: {
			getFilterItemString (filterItemKey, filterItemValue) {
				const heading = this.getFilterItemHeading(filterItemKey);
				const value = this.getFilterItemValueString(filterItemKey, filterItemValue);
				return `${heading}: ${value}`;
			},
			getFilterItemHeading (filterItemKey) {
				return sentenceCase(filterItemKey);
			},
			getFilterItemValueString (filterItemKey, filterItemValue) {
				if (this.getFilterType(filterItemKey) === 'experienceLevelRange') {
					return this.getExperienceLevelRangeFilterString(filterItemKey, filterItemValue);
				}
				if (this.getFilterType(filterItemKey) === 'boolean') {
					return this.getBooleanFilterString(filterItemValue);
				}
				return this.getOptionsFilterString(filterItemKey, filterItemValue);
			},
			getFilterByFilterItemKey (filterItemKey) {
				return this.filters.find((filter) => {
					return filter.value === filterItemKey;
				});
			},
			getFilterType (filterItemKey) {
				return this.getFilterByFilterItemKey(filterItemKey)?.type;
			},
			getBooleanFilterString (filterItemValue) {
				if (filterItemValue) {
					return 'Yes';
				}
				return 'No';
			},
			getExperienceLevelRangeFilterString (filterItemKey, filterItemValue) {
				const min = Math.min(...filterItemValue);
				const max = Math.max(...filterItemValue);
				if (min !== max) {
					return `${min}-${max}`;
				}
				return this.getFilterByFilterItemKey(filterItemKey)?.options?.find((option) => {
					return option.value === min;
				}).text;
			},
			getFilterOptionText (filterItemKey, filterItemValue) {
				return this.getFilterByFilterItemKey(filterItemKey)?.options?.find((option) => {
					return option.value === filterItemValue;
				})?.text;
			},
			getOptionsFilterString (filterItemKey, filterItemValue) {
				const firstItem = (this.getFilterOptionText(filterItemKey, filterItemValue[0]));
				if (filterItemValue.length > 1) {
					const length = filterItemValue.length;
					const lengthOfRest = length - 1;
					return `${firstItem} and ${lengthOfRest} more`;
				}
				return firstItem;
			},
			changeFilters () {
				this.$emit('changeFilters');
			},
			removeFilter (filter) {
				this.$emit('removeFilter', filter);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='active-filters'] {
		padding:rem(10) 0;

    .btn {
      margin-bottom:rem(10);
    }

		[data-element='filter-items'] {
			display:flex;
			flex-wrap:wrap;
			align-items:center;
			margin-right:rem(2);
			[data-component='badge'] {
				margin:0 rem(10) rem(10) 0;
			}
		}
	}
</style>
