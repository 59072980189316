<template>
	<div data-component="modal-inner">
		<div
			data-element="modal-content"
			:data-size="size"
			:data-left-aligned="leftAligned"
		>
			<div data-element="modal-header">
				<h1
					v-if="heading"
					data-element="modal-heading"
				>
					{{ heading }}
				</h1>
				<V2Button
					variant="text"
					class="close-btn"
					@click="close"
				>
					<icon icon="v2-cross" colour="transaprent" />
				</V2Button>
			</div>
			<div data-element="modal-slot">
				<slot
					@close="close"
				/>
			</div>
		</div>
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';
	import V2Button           from '@/components/v2/ui/V2Button';

	export default {
		components: {
			Icon,
			V2Button
		},
		props: {
			heading: {
				type: String,
				default: undefined
			},
			size: {
				type: String,
				validator (value) {
					return [
						'sm',
						'md',
						'lg'
					].includes(value);
				},
				default: 'lg'
			},
			leftAligned: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			close () {
				this.$emit('close');
			},
			change () {
				this.$emit('change');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='modal-inner'] {
		@include grid;
		display:flex;
		flex-direction:column;
		margin:auto 0;
    border-radius: 4px;

    @media (max-width: 1279px) {
      padding-right: 0;
    }

		[data-element='modal-content'] {
			@include cols(12);
			background: $c-bg-white;
      border-radius: 4px;

			&[data-size='sm'] {
				@include mq('sm') {
					@include cols(6);
					@include push(3);
				}
			}

			&[data-size='md'] {
				@include mq('sm') {
					@include cols(8);
					@include push(2);
				}
			}

			&[data-size='lg'] {
				@include mq('sm') {
					@include cols(10);
					@include push(1);
				}
			}

			&[data-left-aligned] {
				@include mq('sm') {
					@include cols(8);
					@include push(0);
				}
			}

			[data-element='modal-header'] {
        display: flex;
        padding: 12px 16px;
        justify-content: space-between;
        background: $c-white-l;
        border-radius: 4px;

        h1 {
          @include font(18px, $c-dark-navy, 600, 23px);
        }

        .close-btn {
          width: 22px;
          height: 22px;
          padding: 0;
        }
			}

			[data-element='modal-slot'] {
				padding:rem(24) rem(24) rem(24);
			}
		}
	}

</style>
