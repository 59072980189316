<template>
	<div data-component="table-view">
		<div data-element="controls">
			<filters
				v-if="filters"
				:filters="filters"
				:showClearFilters="showClearFilters"
				:isBrowseCourses="isBrowseCourses"
				@filter="onFilter"
			/>
			<search-input
				v-if="showSearch"
				:inputPlaceholder="searchPlaceholder"
				@search="onSearch"
			/>
		</div>
		<table-view-row
			:isHeader="true"
			:headings="table.headings"
		/>
		<table-view-row
			v-for="(row, index) in table.rows"
			:headings="table.headings"
			:data-highlight="isHighlighted(index)"
			:key="row.id"
			v-bind="row"
		/>
		<records-count
			:pagination="pagination"
			:total="totalItems"
		/>
		<pagination
			v-if="showPagination"
			v-bind="pagination"
			@paginate="onPaginate"
		/>
	</div>
</template>

<script>

	import SearchInput   from '@/components/ui/views/SearchInput';
	import Filters       from '@/components/ui/views/filters/Filters';
	import TableViewRow  from '@/components/ui/views/tableView/TableViewRow';
	import Pagination    from '@/components/ui/views/Pagination';
	import RecordsCount  from '@/components/ui/views/RecordsCount';

	export default {
		components: {
			SearchInput,
			Filters,
			TableViewRow,
			Pagination,
			RecordsCount
		},
		props: {
			showSearch: {
				type: Boolean,
				default: true
			},
			searchPlaceholder: {
				type: String,
				default: undefined
			},
			showPagination: {
				type: Boolean,
				default: true
			},
			showClearFilters: {
				type: Boolean,
				default: false
			},
			isBrowseCourses: {
				type: Boolean,
				default: false
			},
			filters: {
				type: Array,
				default: undefined
			},
			table: {
				type: [Object, Boolean],
				required: true
			},
			pagination: {
				type: Object,
				default: undefined
			},
			totalItems: {
				type: Number,
				default: 0
			}
		},
		methods: {
			onSearch (value) {
				this.$emit('search', value);
			},
			onFilter (value) {
				this.$emit('filter', value);
			},
			onPaginate (value) {
				this.$emit('paginate', value);
			},
			isHighlighted (index) {
				if (!this.table.highlighted) {
					return false;
				}
				return this.table.highlighted[index];
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='table-view'] {
		@include cols(12);
		[data-element='controls'] {
			@include nested-grid;
			margin:rem(22) 0;
			[data-component='filters'] {
				margin-bottom:rem(22);
			}
		}
		[data-component='pagination'] {
			margin-top:rem(12);
		}
		[data-component='records-count'] {
			margin-top:rem(24);
		}
		[data-highlight] {
			// modify this to something better when we do UI
			opacity: 0.5;
		}
  }

</style>
