<template>
	<div data-component="filter-modal">
		<modal-inner
			@close="closeModal"
			heading="Filters"
		>
			<div data-element="filter-items">
				<filter-item
					v-for="(filter, key) in filters"
					:key="key"
					:data-type="filter.type"
					:filter="filter"
					:activeOptions="getActiveFilterOptionsByFilter(filter)"
					:value="getFilterValueByFilter(filter)"
					@addFilterOption="addFilterOption"
					@removeFilterOption="removeFilterOption"
					@setFilterValue="setFilterValue"
				/>
			</div>
		</modal-inner>
	</div>
</template>

<script>

	import FilterItem  from '@/components/ui/views/filters/FilterItem';
	import ModalInner  from '@/components/ui/modal/ModalInner';

	export default {
		components: {
			FilterItem,
			ModalInner
		},
		props: {
			filters: {
				type: Array,
				default: undefined
			},
			activeFilters: {
				type: Object,
				default: () => ({})
			}
		},
		computed: {},
		methods: {
			getFilterValueByFilter (filter) {
				return this.activeFilters[filter.value];
			},
			getActiveFilterOptionsByFilter (filter) {
				return this.activeFilters[filter.value];
			},
			addFilterOption ({ filter, option }) {
				this.$emit('addFilterOption', { filter, option });
			},
			removeFilterOption ({ filter, option }) {
				this.$emit('removeFilterOption', { filter, option });
			},
			setFilterValue ({ filter, value }) {
				this.$emit('setFilterValue', {
					filter,
					value
				});
			},
			clearAllFilterOptions () {
				this.$emit('clearAllFilterOptions');
			},
			closeModal () {
				this.$emit('closeModal');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='filter-modal'] {
		width:100%;
		[data-element='filter-items'] {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 0 1rem;
		}
	}

  ::v-deep [data-component='filter-boolean'] {
    padding-bottom: 0 !important;
  }

</style>
